<template>
  <div class="evaluation">
    <!-- 心理测评 -->
    <div class="introduce">
      <div class="WebMain">
        <p class="DivTitle">快速、准确、权威的测评系统</p>
        <!-- <p class="p17 text">
          心理测评系统是依据专业的心理学理论，使用高度智能化的操作程序，给人的能力、人格及心理健康等心理特性和行为确定出一种数量化的价值。将人的某些心理特征数量化，来衡量个体心理因素水平和个体心理差异的一种科学测量方法。智涵易兴提供心理测评系统定制开发，所有功能可控可改。
        </p> -->
        <div class="content">
          <div class="left">
            <img src="../../assets/img/evaluation/编组 12备份 4@2x.png" alt="">
            <p class="p17">
              大象心理测评系统借助先进的人工智能技术、自然语言处理技术、神经网络深度学习技术，结合前沿脑科学理论，优化了数据分析和数据管理过程，以增强对被测者心理状态的理解和研判。我们的专业的医疗团队和深度学习算法技术人员，为智能系统提供持续不断的优化和升级，为用户提供精准、科学、权威的心理评估及心理健康趋势分析管理。
            </p>
            <!-- <p class="p17">多操作端: 支持PC端、平板端、手机端、微信小程序等。</p>
            <p class="p17">多维度模型: 提供多种评估模型，包括宏观、微观、生理、心理等多维度评估。</p>
            <p class="p17">数据准确: 使用前沿的大数据分析技术，确保测评数据的准确性及可靠性。</p>
            <p class="p17">个性化分析: 提供个性化的分析和建议，实现对每一位用户的精准管理。</p> -->
          </div>
          <div class="right">
            <img src="../../assets/img/evaluation/三端@2x.png" alt="">
          </div>
        </div>
        <div class="testBtn">
          <a href="https://ig.zhihanyixing.com" target="blank">
          <span> 立即试用</span></a>
        </div>
      </div>
    </div>
    <div class="function">
      <div class="WebMain">
        <p class="DivTitle">技术亮点与功能优势</p>
        <div class="DivContent">
          <div class="item" v-for="item in functionList" :key="item.id">
            <p class="num p50">0{{ item.id }}</p>
            <p class="title p25">{{ item.title }}</p>
            <p class="content p17">{{ item.content }}</p>
            <div class="bottomStyle"></div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$store.state.isPhone ? 'phone development' : 'development'">
      <div class="WebMain">
        <p class="DivTitle">支持特殊功能及量表定制开发</p>
        <div class="DivContent">
          <div class="item " v-for="item in developmentList" :key="item.id">
            <img class="icon" :src="require(`../../assets/img/evaluation/${item.icon}`)" alt="">
            <img class="hoverIcon" :src="require(`../../assets/img/evaluation/${item.hoverIcon}`)" alt="">
            <div class="content">

              <p class="top p24">
                {{ item.title }}
              </p>
              <p class="text">
                {{ item.content }}
              </p>
            </div>
            <div class="leftStyle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      functionList: [
        { id: 1, title: '测评工具库', content: '系统包含40余种常用量表，支持自定义量表，自定义报告。可对常模、结果解释、辅导建议、参考值等进行编辑修订。' },
        { id: 2, title: '自动测评报告', content: '自动生成专业详细的测评报告，对用户的心理状态进行分析和解释，并提供相应的建议和行动方案。支持测评报告分级分权限管理、支持导出PDF、word、图片，支持不限次打印。' },
        { id: 3, title: '层级化管理', content: '系统采用目前先进的系统权限配合组织结构管理概念。通过管理员、操作员、咨询师、测评用户等多个角色，结合树状组织机构的管理方式，可根据不同组织、企业的实际情况配置。' },
        { id: 4, title: '安全可靠', content: '系统采用先进的非对称数据加密技术，多种安全保密措施，确保用户信息和测试结果的安全性和保密性，确保测评结果只有特定的授权用户方可查看和使用。保证数据的机密性和完整性。' },
        { id: 5, title: '科学性与标准化', content: '量表和报告由专业医师、心理咨询师专家团队设计，基于科学理论和研究，使用标准化的测量工具，保证评估结果的可靠性和准确性。' },
        { id: 6, title: '数据分析与可视化', content: '通过数据分析和可视化功能，帮助用户更好地理解和解释测试结果。支持多层级数据统计与分析，支持团体图文报告。' },
      ],
      developmentList: [
        { id: 1, title: '按需定制', content: '定制专属的功能及量表，最大程度满足您的开发需求', icon: 'icon (1).png', hoverIcon: 'hover (1).png' },
        { id: 2, title: '源码交付', content: '定制系统开发完成后，源码完整的交付', icon: 'icon (2).png', hoverIcon: 'hover (2).png' },
        { id: 3, title: '终身使用', content: '一次购买终身使用，无隐藏费用', icon: 'icon (3).png', hoverIcon: 'hover (3).png' },
        { id: 4, title: '更新迭代', content: '可按照需求持续优化，更新迭代，逐步完善', icon: 'icon (4).png', hoverIcon: 'hover (4).png' },
        { id: 5, title: '合同保障', content: '签订软件定制开发合同，优质的服务与售后保障', icon: 'icon (5).png', hoverIcon: 'hover (5).png' },
        { id: 6, title: '自由配色', content: '提供丰富的主题色和界面定制服务', icon: 'icon (6).png', hoverIcon: 'hover (6).png' },
      ]
    }
  },
  methods: {
    // // 跳转到心理测评
    // toTest() {

    // }
  }
}
</script>

<style lang="scss" scoped>
.evaluation {

  .introduce {
    padding-bottom: 60px;
    background: url('../../assets/img/evaluation/bg.png');
    background-size: cover;

    .content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 20px;

      .left {
        width: 695px;

        img {
          height: 65px;
        }

        p {
          color: #666666;
          line-height: 27px;
          margin-top: 20px;
        }
      }

      .right {
        padding-top: 20px;

        img {
          width: 410px;
        }


      }

    }

    .testBtn {
      margin-top: 30px;
      a{
        text-decoration: none;
      }
      span {
        cursor: pointer;
        margin: auto;
        text-align: center;
        display: block;
        width: 148px;
        height: 42px;
        border-radius: 31px;
        border: 1px solid #93D0CF;
        font-size: 16px;
        font-weight: 600;
        color: #93D0CF;
        line-height: 42px;
      }

      &:hover {
        span {
          transition: all .5s;
          background: #93D0CF;
          color: white;
        }
      }
    }
  }

  .function {
    background: url('../../assets/img/evaluation/bg2.png');
    background-size: cover;

    .DivContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        width: 560px;
        box-sizing: border-box;
        padding: 20px 0;
        position: relative;
        margin-bottom: 40px;

        &:hover {

          .bottomStyle {
            transition: all .5s;
            width: 100%;
          }

          .title {
            transition: all .5s;
            color: var(--custom-color);
          }
        }

        .bottomStyle {
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 0;
          height: 2px;
          background: var(--custom-color);
        }

        .num {
          position: absolute;
          top: -10px;
          font-weight: 600;
          color: var(--custom-color);
          opacity: 0.3;

        }

        .title {
          font-weight: 600;
          color: #222222;
          line-height: 36px;
          margin-bottom: 20px;
        }

        .content {
          color: #666666;
          line-height: 27px;
        }
      }
    }
  }

  .development {
    // background: #F5F5F6;
    background: linear-gradient(180deg, #F8FDFF 0%, #D5EDF3 100%);
    padding-bottom: 60px;

    .DivContent {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .item {
        box-sizing: border-box;
        width: 390px;
        min-height: 160px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        position: relative;

        &:hover {
          .hoverIcon {
            display: block;
          }

          .icon {
            display: none;
          }

          .leftStyle {
            height: 100%;
            transition: all .5s;
          }
        }

        img {
          width: 92px;
          position: absolute;
          top: 0;
          right: 0;

        }

        .hoverIcon {
          display: none;
        }

        .content {
          padding: 24px 35px;

          .top {
            margin-bottom: 16px;

          }



          .text {
            color: #666666;
            line-height: 27px;
          }
        }

        .leftStyle {
          position: absolute;
          left: 0;
          top: 0;
          width: 7px;
          height: 0;
          background: var(--custom-color);
        }
      }
    }
  }
}

.evaluation .development.phone .DivContent .item .hoverIcon {
  display: block;
}

.evaluation .development.phone .DivContent .item .icon {
  display: none;
}

@media screen and (min-width:1180px) {
  .evaluation .introduce .content .left {
    margin-right: 45px;
  }

  .evaluation .function .DivContent .item {

    &:first-child,
    &:nth-child(3),
    &:nth-child(5) {
      margin-right: 60px;
    }
  }
}

@media screen and (max-width:500px) {
  .evaluation .introduce .content .right img {
    width: 100%;
  }
}</style>